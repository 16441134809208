export const programmePublicInfo = {
  BAEC: {
    name: 'British Airways',
    domainName: 'BA.com',
    clubName: 'British Airways Executive Club',
  },
  AERC: {
    name: 'Aer Lingus',
    domainName: 'Aerlingus.com',
    clubName: 'AerClub',
  },
  IBP: {
    name: 'Iberia',
    domainName: 'Iberia.com',
    clubName: 'Club Iberia Plus',
  },
  ATRP: {
    name: 'Vueling',
    domainName: 'Vueling.com',
    clubName: 'Vueling Club',
  },
  AY: {
    name: 'Finnair',
    clubName: 'Finnair Plus',
  },
};
