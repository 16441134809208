import styled, { css } from 'styled-components';
import { media } from '@/utils/viewports';
import Icon from '@/components/Icon';

export const HeaderContainer = styled.div`
  grid-area: Header;
  background: ${props => props.theme.header.background};
  display: grid;
  grid-template-columns: minmax(18.4rem, auto) 123.2rem minmax(18.4rem, auto);
  grid-template-rows: 1fr;
  grid-template-areas: 'LeftMargin ContentContainer RightMargin';
  border-bottom: ${props => props.theme.header.borderBottom};
  box-shadow: ${props => props.theme.header.boxShadow};
  height: 8rem;

  ${media.xxl} {
    grid-template-columns: minmax(2.4rem, auto) 123.2rem minmax(2.4rem, auto);
  }

  ${media.xl} {
    grid-template-columns: 2.4rem 1fr 2.4rem;
  }

  ${media.l} {
    grid-template-columns: 2.4rem 1fr 2.4rem;
  }

  ${media.m} {
    height: 6.8rem;
  }
`;

export const MainLogoImage = styled.img<{
  $isBAEC: boolean;
  $priority: boolean;
}>`
  ${media.m} {
    display: ${({ $isBAEC }) => ($isBAEC ? 'none' : 'block')};
    height: 3rem;
  }
`;

export const IberiaLogoImage = styled.img<{
  $priority: boolean;
}>`
  object-fit: cover;
  width: 35rem;

  ${media.l} {
    width: 25rem;
  }

  ${media.s} {
    width: 20rem;
  }
`;

export const VuelingLogoImage = styled.img<{
  $priority: boolean;
}>`
  height: 4rem;
`;

export const BAECMobileOnlyLogoImage = styled.img<{ $isBAEC: boolean }>`
  display: none;
  padding: 0;
  height: 2rem;
  width: 7.7rem;

  ${media.m} {
    display: ${({ $isBAEC }) => ($isBAEC ? 'block' : 'none')};
  }
`;

export const StyledAviosImage = styled.img`
  width: 4.9rem;
  height: 4.8rem;

  ${media.l} {
    width: 4.09rem;
    height: 4rem;
  }

  ${media.s} {
    width: 2.25rem;
    height: 2.2rem;
  }
`;

export const MenuIcon = styled(Icon)`
  display: none;
  ${media.m} {
    display: block;
  }

  stroke: #fff;
`;

export const RightBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  ${media.m} {
    display: ${props => props.theme.header.rightBlock.mobileDisplay};
  }
`;

export const ContentContainer = styled.div`
  grid-area: ContentContainer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
`;

export const StyledAnchor = styled.a`
  position: relative;

  ${({ theme }) =>
    theme.header.isCentered &&
    css`
      left: 50%;
      transform: translateX(-50%);
    `};
`;

export const StyledRow = styled.div`
  display: flex;
  align-items: center;
  gap: 2rem;
`;

export const StyledOneWorldAnchor = styled.a`
  margin-left: 4.8rem;

  ${media.m} {
    display: none;
  }
`;

export const StyledOneWorld = styled.img`
  height: 2.5rem;
  width: 2.5rem;
  cursor: pointer;
`;
