import { useTranslation } from 'next-i18next';
import {
  FooterContainer,
  LinkContainer,
  LinkContainerAtrp,
  LinkText,
  ExternalLink,
  Image,
  ImageAtrp,
  PartnerLinkContainer,
  LinkTextAtrp,
  PartnerText,
} from './styledComponents';
import { programmePublicInfo } from '@/utils/names';
import { useGlobalContext } from '@/components/GlobalProvider';
import { useRouter } from 'next/router';

const Footer = () => {
  const { programmeId = 'BAEC' } = useGlobalContext();
  const { t } = useTranslation('footer');
  const router = useRouter();

  const programmeUrlToDisplay = programmePublicInfo[programmeId].domainName;
  const programmeUrl = `https://www.${programmeUrlToDisplay}`;

  return (
    <FooterContainer>
      {programmeId === 'ATRP' ? (
        <LinkContainerAtrp>
          <ImageAtrp src={'/assets/logo_avios.svg'} alt="" />
          <ExternalLink href={programmeUrl}>
            {t('goTo')} {programmeUrlToDisplay}
          </ExternalLink>
          <LinkTextAtrp href="/help/faqs">{t('help')}</LinkTextAtrp>
          <LinkTextAtrp
            href="https://www.avios.com/en-GB/privacy"
            target="_blank"
          >
            {t('privacy')}
          </LinkTextAtrp>
          <LinkTextAtrp
            href="/help/terms-and-conditions"
            bigScreenLabel={t('t&cBig')}
            smallScreenLabel={t('t&cSmall')}
            aria-label="Terms and conditions"
          />
          <LinkTextAtrp href="/help/cookies">{t('cookiesPolicy')}</LinkTextAtrp>
        </LinkContainerAtrp>
      ) : (
        <>
          <PartnerLinkContainer href={programmeUrl}>
            <Image src={'/assets/logo_avios.svg'} alt="" />
            <PartnerText>
              {t('goTo')} {programmeUrlToDisplay}
            </PartnerText>
          </PartnerLinkContainer>
          <LinkContainer>
            <LinkText href="/help/faqs">{t('faqs')}</LinkText>
            <LinkText
              href="https://www.avios.com/en-GB/privacy"
              target="_blank"
            >
              {t('privacyPolicy')}
            </LinkText>
            <LinkText
              href="/help/terms-and-conditions"
              bigScreenLabel={t('t&cBig')}
              smallScreenLabel={t('t&cSmall')}
              aria-label="Terms and conditions"
            />
            <LinkText href="/help/cookies">{t('cookies')}</LinkText>

            {programmeId === 'IBP' ? (
              <LinkText
                href={
                  router.locale === 'en'
                    ? 'https://www.iberia.com/es/iberiaplus/contact/'
                    : 'https://www.iberia.com/es/iberiaplus/contacto/'
                }
                target="_blank"
              >
                {t('contactUs')}
              </LinkText>
            ) : (
              <LinkText href="/help/contact-us">{t('contactUs')}</LinkText>
            )}
          </LinkContainer>
        </>
      )}
    </FooterContainer>
  );
};

export default Footer;
